$enable-shadows: false;
$select-font-family: inherit;
$select-font-size: inherit;
$select-line-height: $input-btn-line-height; //formerly line-height-computed

$select-color-text: $input-color; //$gray-800
$select-color-highlight: $gray-400;
$select-color-input: $white;
$select-color-input-full: $select-color-input;
$select-color-input-error: $danger;
$select-color-input-error-focus: darken($select-color-input-error, 10%);
$select-color-disabled: $input-bg;
$select-color-item: $gray-300;
$select-color-item-border: $gray-500;
$select-color-item-active: $component-active-bg;
$select-color-item-active-text: $white;
$select-color-item-active-border: rgba(0, 0, 0, 0);
$select-color-optgroup: $white;
$select-color-optgroup-text: $dropdown-header-color;
$select-color-optgroup-border: $dropdown-divider-bg;
$select-color-dropdown: $white;
$select-color-dropdown-border-top: mix($input-border-color, $input-bg, 0.8);
$select-color-dropdown-item-active: $dropdown-link-hover-bg;
$select-color-dropdown-item-active-text: $dropdown-link-hover-color;
$select-color-dropdown-item-create-active-text: $dropdown-link-hover-color;
$select-opacity-disabled: 0.5;
$select-shadow-input: none;
$select-shadow-input-focus: inset 0 1px 2px rgba($black, 0.15);
$select-shadow-input-error: inset 0 1px 1px rgba($black, .075);
$select-shadow-input-error-focus: inset 0 1px 1px rgba($black, .075), 0 0 6px lighten($select-color-input-error, 20%);
$select-border: 1px solid $input-border-color;
$select-border-radius: $input-border-radius;

$select-width-item-border: 0px;
$select-padding-x: $input-btn-padding-x;
$select-padding-y: $input-btn-padding-y;
$select-padding-dropdown-item-x: $input-btn-padding-x;
$select-padding-dropdown-item-y: 3px;
$select-padding-item-x: 3px;
$select-padding-item-y: 1px;
$select-margin-item-x: 3px;
$select-margin-item-y: 3px;

$select-arrow-size: 5px;
$select-arrow-color: $select-color-text;
$select-arrow-offset: calc(#{$select-padding-x} + 5px);
