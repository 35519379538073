// Helper styles
.cursor-help {
  cursor: help;
}

.cursor-pointer {
  cursor: pointer;
}

.one-line {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.one-line-sm {
  @include media-breakpoint-up(sm) {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
