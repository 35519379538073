// Layout styles
body:not(.bookmarklet) {
  padding-bottom: $nav-link-height + ($navbar-padding-y * 2);

  @include media-breakpoint-up('md') {
    padding-bottom: 0;
    padding-top: $nav-link-height + ($navbar-padding-y * 2);
  }
}

.main {
  padding-top: map_get($spacers, 4);
  padding-bottom: map_get($spacers, 4);
}

.footer {
  opacity: .75;
  padding-bottom: map_get($spacers, 4);
}

@media (max-width: 991px) {
  .container {
    width: 100%;
    max-width: none;
  }
}

.navbar:not(.brand-only) {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-fixed;

  @include media-breakpoint-up('md') {
    top: 0;
    bottom: auto;
  }

  @include media-breakpoint-down('sm') {

    .nav-link {
      display: flex;
      align-items: center;

      .fa-fw {
        margin-left: auto;
      }
    }
  }
}

.card-table {
  margin: -#{$card-border-width};

  .table {

    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    overflow: hidden;
  }
}

.sharing-settings {
  display: flex;
  flex-wrap: wrap;

  .sharing-checkbox {
    flex: 0 0 auto;
    padding: map_get($spacers, 2);
    font-size: $h4-font-size;

    input {
      display: none;
    }

    label {
      color: $text-muted;
      cursor: pointer;
    }

    input:checked + label {
      color: $success;
    }
  }
}

.share-links {
  display: flex;
  flex-wrap: wrap;

  .share-link {
    flex: 0 0 auto;
    margin: map_get($spacers, 1);
  }
}

a.badge {
  text-decoration: none;
}

.alert {
  font-size: 90%;
}

.text-xs {
  font-size: $font-size-xs;
}

.btn-xs {
  @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-border-radius-xs);
}

.btn-group-xs > .btn {
  @extend .btn-xs;
}

.badge.badge-lg {
  font-size: $font-size-sm;
}

code {
  @include font-size($code-font-size);
  color: $code-color;
  word-wrap: break-word;
  background-color: $code-background-color;
  padding: $code-padding;
  border-radius: $code-border-radius;

  a > & {
    color: inherit;
  }
}

.icon {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  font-size: inherit;
  line-height: 1;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;

  path {
    fill: currentColor;
  }

  &.fw {
    width: 1em;
  }
}

.icon-spin {
  -webkit-animation: icon-spin 4s infinite linear;
  animation: icon-spin 4s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#loader {
  display: none !important;
}

.link-thumbnail {
  box-shadow: inset 0 0 1px $secondary;
}

.link-thumbnail-detail {
  width: 100%;
  height: auto;
  padding-bottom: 52.5%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @include media-breakpoint-up('lg') {
    width: 180px;
    height: 93px;
    padding-bottom: 0;
  }
}

.link-thumbnail-list-holder {
  width: 180px;
  height: 93px;

  //@include media-breakpoint-up('lg') {
  //  width: 180px;
  //}

  .link-thumbnail-list {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover !important;
  }
}
