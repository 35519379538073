// Bootstrap 5 Fixes
.form-check-label {
  cursor: pointer;
}

.navbar > .container {
  align-items: normal;
  justify-content: normal;
}

// Remove buplicate border from list groups inside cards
.card .list-group-item:last-of-type {
  border: 0;
}

[disabled] {
  cursor: not-allowed;
}

// Generate a small pagination on mobile devices to prevent overflow
.pagination {
  @include media-breakpoint-only(xs) {
    @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $border-radius-sm);
  }
}

.badge-wrap {
  white-space: normal;
  max-width: 100%;
}
