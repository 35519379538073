// Colors
$white: #fff;
$gray-100: #f1f5f9;
$gray-200: #e2e8f0;
$gray-300: #cbd5e1;
$gray-400: #94a3b8;
$gray-500: #64748b;
$gray-600: #475569;
$gray-700: #334155;
$gray-800: #1e293b;
$gray-900: #0f172a;

//$gray-100: #F6F9FE;
//$gray-200: #E5EDFB;
//$gray-300: #CFDDF5;
//$gray-400: #C6D5EE;
//$gray-500: #98B1D8;
//$gray-600: #6686B9;
//$gray-700: #44679F;
//$gray-800: #2C528F;
//$gray-900: #193C74;

$gray: $gray-500;
$black: #17181A;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);

$blue: #44679F;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #DB3523;
$orange: #FFA602;
$yellow: #F39C12;
$green: #0C9E5A;
$teal: #00A7BA;
$cyan: #3498DB;

$primary: $blue;
$secondary: $gray;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800
);

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
);

$color-contrast-dark: $black;
$color-contrast-light: $white;


// Options
$enable-caret: false;


// Body
$body-bg: $white;
$body-color: $black;
$body-color-muted: $gray;


// Links
$link-color: $primary;
$link-decoration: none;
$link-shade-percentage: 40%;
$link-hover-color: shift-color($link-color, $link-shade-percentage);
$link-hover-decoration: none;


// Components
$border-radius-xs: .2rem;

$line-height-sm: 1.5;


// Typography
$font-family-sans-serif: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-size-base: 1rem;
$font-size-xs: $font-size-base * .75;

$font-weight-normal: 400;

$text-muted: $body-color-muted;


// Tables
$table-dark-bg: $gray-900;
$table-dark-color: $white;


// Buttons + Forms
$input-border-color: $gray-300;
$input-btn-focus-width: .15rem;
$input-btn-padding-y-xs: .15rem;
$input-btn-padding-x-xs: .3rem;
$input-btn-font-size-xs: $font-size-xs;
$input-btn-line-height-xs: $line-height-sm;

$input-placeholder-color: $text-muted;


// Buttons
$btn-padding-y-xs: $input-btn-padding-y-xs;
$btn-padding-x-xs: $input-btn-padding-x-xs;
$btn-font-size-xs: $input-btn-font-size-xs;
$btn-line-height-xs: $input-btn-line-height-xs;

$btn-hover-bg-tint-amount: 20%;
$btn-border-radius-xs: $border-radius-xs;


// Navbar
$navbar-dark-color: rgba($white, .75);
$navbar-dark-hover-color: rgba($white, 1);
$navbar-dark-disabled-color: rgba($white, .4);
$navbar-brand-font-size: $font-size-base * 1.7;

// Badges
$badge-font-weight: $font-weight-normal;


// Code
$code-font-size: 85%;
$code-color: $teal;
$code-background-color: $gray-100;
$code-padding: .15rem .2rem;
$code-border-radius: .2rem;
